import { Injectable } from '@angular/core';
import { AmplitudeAnalyticsService, LoginEvent } from '@modules/analytics/services';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { firstValueFrom, from, mergeMap, of, single } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { StateResetAll } from 'ngxs-reset-plugin';
import {
  CheckToAssignSurveyInviteForUserGQL,
  GetMyselfGQL,
  GetMyselfQuery,
  LoginGQL,
  LoginMutation,
  LogoutGQL,
  TokenAuthenticationGQL,
  UpdateUserGQL,
  UpdateUserProfileGQL,
  UpdateUserTourGQL,
  UserRole,
  UserSciopsData,
} from '../../../generated/graphql';
import { LoginUser, LogoutUser, RefreshUserData, TokenLogin, UpdateUser, UpdateUserLoginState, UpdateUserProfile, UpdateUserTour } from './loggedInUser.actions';
import { LanguageToolsService } from '@common/services/language-tools.service';
import { Characters } from '../enum/characters';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core/types';
// import { PgToastService } from '@common/services';

export type LoggedInUser = LoginMutation['login'];
export type LoggedInUserProfile = LoggedInUser['profile'];

export interface LoggedInUserStateModel {
  isLoggedIn: boolean;
  gameSave?: UserSciopsData;
  user?: LoggedInUser;
  error?: string;
}

@State<LoggedInUserStateModel>({
  name: 'loggedInUser',
  defaults: {
    user: undefined,
    gameSave: undefined,
    error: '',
    isLoggedIn: false,
  },
})
@Injectable()
export class LoggedInUserState {
  constructor(
    private store: Store,
    private loginGQL: LoginGQL,
    private logoutService: LogoutGQL,
    private updateUserService: UpdateUserGQL,
    private updateUserProfileService: UpdateUserProfileGQL,
    private updateUserTourService: UpdateUserTourGQL,
    private getMyselfGQL: GetMyselfGQL,
    private tokenAuthenticationGQL: TokenAuthenticationGQL,
    private apollo: Apollo,
    private router: Router,
    private readonly amplitudeAnalyticsService: AmplitudeAnalyticsService,
    private readonly languageToolsService: LanguageToolsService,
    private checkToAssignSurveyInviteForUserGQL: CheckToAssignSurveyInviteForUserGQL
    // private readonly pgToastService: PgToastService
  ) {}

  // Selector to get login error message from the state
  @Selector()
  static LoginError(state: LoggedInUserStateModel) {
    return state.error || '';
  }

  // Selector to get the user's character image
  @Selector()
  static UserCharacter(state: LoggedInUserStateModel) {
    const character = state.user?.profile.character || Characters.CHAR_01;
    return `assets/img/characters/full/${character}.png`;
  }

  // Selector to get the user's character avatar image based on their role
  @Selector()
  static UserCharacterAvatar(state: LoggedInUserStateModel) {
    if (state.user) {
      const role = state.user?.role;
      if (role == UserRole.Student) {
        const character = state.user?.profile.character || Characters.CHAR_01;
        return `assets/img/characters/avatar/${character}.png`;
      } else if ([UserRole.Teacher, UserRole.District, UserRole.Admin, UserRole.User].includes(role)) {
        return `https://dkmzd4tgwtjws.cloudfront.net/public/images/profile_pictures/plasma_logo_blue.png`;
      }
    }
  }

  // Selector to get the logged-in user's information
  @Selector()
  static getLoggedInUser(state: LoggedInUserStateModel): LoggedInUser | undefined {
    return state.user;
  }
  // Selector to get the logged-in user's information
  @Selector()
  static getLoggedInUserRole(state: LoggedInUserStateModel): UserRole | undefined {
    return state.user?.role;
  }

  // Selector to determine if user is logged in
  @Selector()
  static isUserLoggedIn(state: LoggedInUserStateModel): boolean {
    return state.isLoggedIn;
  }

  // Action to log in the user
  @Action(LoginUser)
  loginUser(stateCtx: StateContext<LoggedInUserStateModel>, { email, password, redirectUrl }: LoginUser) {
    const state = stateCtx.getState();
    let loginAmplitudeEvent: LoginEvent = { loginSuccess: 'FAILED' };

    // If the user is already logged in, do nothing
    if (!(!state || !state.isLoggedIn)) {
      return;
    }

    // Reset all state before logging in
    return this.store.dispatch(new StateResetAll()).pipe(
      mergeMap(() =>
        this.loginGQL
          .mutate(
            {
              email,
              password,
            },
            { fetchPolicy: 'no-cache' }
          )
          .pipe(
            catchError((err) => {
              const error = err?.message;
              stateCtx.setState({
                isLoggedIn: false,
                error,
              });
              // this.pgToastService.showError(error);
              return of(error);
            }),
            mergeMap((result) => from(this.handleLogin(stateCtx, result?.data?.login, loginAmplitudeEvent, 'LOGIN CLICKED', redirectUrl)))
          )
      )
    );
  }

  // Handles post-login actions, including setting state and navigating the user
  private async handleLogin(
    stateCtx: StateContext<LoggedInUserStateModel>,
    loginResultData: LoggedInUser | null,
    loginAmplitudeEvent: LoginEvent,
    loginInEventName: string,
    redirectUrl?: string | null
  ) {
    if (!loginResultData) {
      console.error('No Login GQL Result Data was Found');
      // this.pgToastService.showError('No Login GQL Result Data was Found');
      return;
    }

    const user: LoggedInUser = loginResultData;
    const userLang = user.profile.language || 'en';

    const userRoleRoute = this.getUserRoleRoute(user);

    // Set state for the logged-in user
    stateCtx.setState({ user, isLoggedIn: true });

    // Set language cookie
    await this.setLangCookie(userLang);

    // Redirect user based on their role or a custom URL
    const navigateUrl = redirectUrl && redirectUrl.includes(userRoleRoute) ? redirectUrl : userRoleRoute;

    // Update and track analytics
    this.handleAmplitudeAnalytics(loginAmplitudeEvent, loginInEventName, user.role, navigateUrl);

    //TODO add a method for handling custom user login logic checks

    await this.handleCustomUserLoginLogic(user, stateCtx);

    // Navigate user to the appropriate route
    await this.router.navigateByUrl(navigateUrl);
  }

  // Handles Amplitude analytics tracking for login events
  private handleAmplitudeAnalytics(loginAmplitudeEvent: LoginEvent, loginInEventName: string, userRole: UserRole, redirectUrl: string) {
    // Update login success and role information
    loginAmplitudeEvent.redirectUrl = redirectUrl;
    loginAmplitudeEvent.loginSuccess = 'SUCCEEDED';
    loginAmplitudeEvent.userRole = userRole;

    // Track login event with Amplitude
    this.amplitudeAnalyticsService.trackLogin(loginAmplitudeEvent, loginInEventName);
  }

  // Get route based on user role
  private getUserRoleRoute(user: LoggedInUser) {
    const rolesMap = {
      [UserRole.Admin]: '/admin',
      [UserRole.Teacher]: '/teacher',
      [UserRole.Student]: '/student',
      [UserRole.District]: '/district',
      [UserRole.User]: '/user',
    };
    return rolesMap[user.role as UserRole];
  }

  // Custom logic for handling custom user role login actions
  private async handleCustomUserLoginLogic(user: LoggedInUser, stateCtx: StateContext<LoggedInUserStateModel>) {
    if (user.role === UserRole.Student) {
      const userUpdate = await firstValueFrom(
        this.checkToAssignSurveyInviteForUserGQL
          .mutate(
            { getUserDto: { _id: user._id } },
            {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            }
          )
          .pipe(
            tap((result) => {
              if (result.errors) {
                console?.error(`Received errors when calling checkingToAssignSurveyInvite for ${user._id.toString()} with errors ${JSON.stringify(result.errors)}`);
              }
            }),
            mergeMap(() => this.getMyselfGQL.fetch({}, { fetchPolicy: 'no-cache' }).pipe(map((result) => result.data?.getMe)))
          )
      );
      if (userUpdate) {
        stateCtx.patchState({ user: userUpdate });
      }
    }
  }

  // Set language cookie for the user
  async setLangCookie(lang: 'en' | 'es') {
    this.languageToolsService.switchLanguageTo(lang);
  }

  @Action(UpdateUserLoginState)
  async updateUserLoginState(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUserLoginState) {
    ctx.patchState({
      isLoggedIn: action.payload.isLoggedIn,
      gameSave: action.payload.gameSave,
      user: action.payload.user,
      error: action.payload.error,
    });
  }

  // Action to log out the user
  @Action(LogoutUser)
  async logoutUser(ctx: StateContext<LoggedInUserStateModel>, action: LogoutUser) {
    function buildErrorMessage(error?: string): string {
      if (error && error != 'Unauthorized') {
        return `Logged out because of the following: ${error}.  Please try logging in again.`;
      } else {
        return '';
      }
    }

    const state: LoggedInUserStateModel = ctx.getState();
    const maybeErrorMessage = buildErrorMessage(state?.error || action.error);

    if (state?.isLoggedIn) {
      return this.logoutService.mutate().pipe(
        tap(({ data }) => {
          if (data?.logout) {
            this.apollo.client.stop(); //Stops all ongoing queries and subscriptions before resetting the store.
            this.apollo.client.resetStore().then(() => {
              this.logoutAndRedirect(maybeErrorMessage);
            });
          }
        })
      );
    } else {
      this.store.dispatch(new Navigate([`/auth/login`], { error: maybeErrorMessage }));
    }
  }

  private logoutAndRedirect(maybeErrorMessage?: string) {
    this.store
      .dispatch(new UpdateUserLoginState({ isLoggedIn: false, gameSave: undefined, user: undefined, error: maybeErrorMessage }))
      .pipe(single())
      .subscribe(() => {
        // This ensures Navigate is dispatched only after UpdateState completes
        this.store.dispatch(new Navigate(['/auth/login'], { error: maybeErrorMessage }));
      });
  }

  // Action to update user details
  @Action(UpdateUser)
  updateUser(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUser) {
    const state = ctx.getState();
    if (state?.isLoggedIn) {
      ctx.patchState({ error: '' });
      return this.updateUserService
        .mutate(
          {
            getUser: { _id: state.user?._id },
            updateUser: action.updateUser,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(({ data }) => {
            if (typeof data?.updateUser !== 'undefined') {
              const user: LoggedInUser = data?.updateUser;
              ctx.patchState({ user });
              if (action.route) {
                this.store.dispatch(new Navigate([action.route]));
              }
            }
          })
        );
    }
  }

  // Action to update user profile information
  @Action(UpdateUserProfile)
  updateUserProfile(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUserProfile) {
    const state = ctx.getState();
    if (state?.isLoggedIn) {
      ctx.patchState({ error: '' });
      return this.updateUserProfileService
        .mutate(
          {
            getUser: { _id: state.user?._id },
            userProfile: action.userProfile,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(({ data }) => {
            if (typeof data?.updateUserProfile !== 'undefined') {
              const user: LoggedInUser = data.updateUserProfile;
              ctx.patchState({ user });
              if (action.route) {
                this.store.dispatch(new Navigate([action.route]));
              }
            }
          })
        );
    }
  }

  // Action to update the user's tour completion status
  @Action(UpdateUserTour)
  updateUserTour(ctx: StateContext<LoggedInUserStateModel>, action: UpdateUserTour) {
    const state = ctx.getState();
    if (state?.isLoggedIn) {
      ctx.patchState({ error: '' });
      return this.updateUserTourService
        .mutate(
          {
            getUser: { _id: state.user?._id },
            completedToursDto: action.completedToursDto,
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          catchError((err) => {
            const error = err?.message;
            ctx.setState({
              isLoggedIn: false,
              error,
            });
            return of(error);
          }),
          tap(({ data }) => {
            if (typeof data?.updateUserTour !== 'undefined') {
              const user: LoggedInUser = data.updateUserTour;
              ctx.patchState({ user });
            }
          })
        );
    }
  }

  // Action to refresh user data from the server
  @Action(RefreshUserData)
  refreshUserData({ patchState, getState, setState }: StateContext<LoggedInUserStateModel>) {
    const userId = getState().user?._id;
    if (!userId) {
      console.warn('No user ID found. Aborting refresh.');
      return;
    }

    return this.getMyselfGQL.fetch({}, { fetchPolicy: 'no-cache' }).pipe(
      catchError((err) => {
        console.error(`Error refreshing user data: ${JSON.stringify(err)}`);
        const errorMessage = err?.message || 'Unknown error occurred';
        // Set error state and mark user as logged out
        setState({
          user: undefined,
          isLoggedIn: false,
          error: errorMessage,
        });
        // this.pgToastService.showError(errorMessage);
        return of(errorMessage);
      }),
      tap(({ data }: ApolloQueryResult<GetMyselfQuery>) => {
        const updatedUserData = data.getMe;
        if (updatedUserData) {
          patchState({
            user: updatedUserData,
          });
        }
      })
    );
  }

  // Action to log in the user using a token (e.g., social login)
  @Action(TokenLogin)
  async tokenLogin(stateCtx: StateContext<LoggedInUserStateModel>, { token }: TokenLogin) {
    const state = stateCtx.getState();

    let loginAmplitudeEvent: LoginEvent = { loginSuccess: 'FAILED' };

    console.log(state, state.isLoggedIn);
    return this.store.dispatch(new StateResetAll()).pipe(
      mergeMap(() =>
        this.tokenAuthenticationGQL.mutate({ token: token }, { fetchPolicy: 'no-cache' }).pipe(
          catchError((err) => {
            const error = err?.message;
            stateCtx.setState({
              isLoggedIn: false,
              error,
            });
            // this.pgToastService.showError(error);
            return of(error);
          }),
          mergeMap((result) => from(this.handleLogin(stateCtx, result?.data?.tokenAuthentication, loginAmplitudeEvent, 'LOGIN/SIGN UP WITH GOOGLE CLICKED')))
        )
      )
    );
  }
}
