import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from '@common/app-common.module';
import * as authStates from '@modules/auth/store';
import { NavigationModule } from '@modules/navigation/navigation.module';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxsModule } from '@ngxs/store';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { AppGraphQLModule } from './app-graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ApolloModule } from 'apollo-angular';
import { AnalyticsModule } from '@modules/analytics/analytics.module';
import { SentryModule } from '../sentry/sentry.module';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphqlInterceptorService } from '@modules/util/graphql-interceptor.service';

/* States */
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    NgxsModule.forRoot([...authStates.states]), // Ensure this is called before any feature modules or plugins
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppCommonModule.forRoot(),
    NavigationModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ keys: [authStates.LoggedInUserState] }),
    NgxsRouterPluginModule.forRoot(),
    NgxSpinnerModule,
    TourNgBootstrapModule,
    AppGraphQLModule,
    ApolloModule,
    AnalyticsModule,
    MdbSelectModule,
    MdbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ...environment.plugins,
  ],
  providers: [
    withNgxsResetPlugin(),
    ...SentryModule.providers(),
    { provide: 'googleTagManagerId', useValue: environment.GOOGLE_TAG_ID },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphqlInterceptorService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
