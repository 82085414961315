import * as Sentry from '@sentry/angular';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import packageJson from '../../package.json';

export class SentryModule {
  static initSentry() {
    Sentry.init({
      dsn: 'https://efe96cdd70ed4f0eb447950780bc5631@o4505607931953152.ingest.sentry.io/4505607934509056',
      // This sets the sample rate to be 0%. We only want to capture relays (right now) for users with errors in the session
      replaysSessionSampleRate: 0.0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.breadcrumbsIntegration({ console: true, xhr: true }),
        Sentry.httpClientIntegration(),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      environment: environment.name,
      release: packageJson.name + '@' + packageJson.version,
    });
  }

  static providers() {
    return [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ];
  }
}
